.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.scroll-prompt {
	position: absolute;
	display: block;
	height: 40px;
	width: 20px;
	border: 2px solid white;
	bottom: 20px;
	border-radius: 30px;
	left: 50%;
	transform: translate(-50%, 0);
}

.scroll-prompt .scroll-cursor {
	position: absolute;
	display: block;
	width: 10px;
	height: 10px;
	background-color: white;
	border-radius: 100%;
	left: 50%;
	transform: translateX(-50%);
	top: 5px;
	animation: scrollAnimation 1500ms ease-out infinite forwards;
}

@keyframes scrollAnimation {
	from {
		opacity: 1;
		top: 5px;
	}

	to {
		opacity: 0;
		top: calc(100% - 15px);
	}
}
