.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f5f5;
	padding: 10px;
}

.navbar__logo {
	font-weight: bold;
}

.navbar__links {
	display: flex;
	list-style-type: none;
}

.navbar__link {
	margin-left: 20px;
	cursor: pointer;
}

.navbar__button button {
	background-color: none;
	border: 1px solid white;
	padding: 10px;
}

@media (max-width: 600px) {
	.navbar__links {
		display: none;
	}

	.navbar__button {
		display: block;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideRight {
	from {
		right: -100%;
	}
	to {
		right: 0%;
	}
}

.navbar__drawer {
	animation: slideRight 200ms ease-out;
}

.fade {
	animation: fadeIn 200ms ease-out;
}
